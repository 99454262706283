import peltonen from "./popup_images/peltonen.jpeg";
import bazaar from "./popup_images/bazaar.jpeg";
import bullsonp from "./popup_images/bullsonp.jpeg";
import pahis from "./popup_images/pahavaanii.jpeg";
import geo from "./popup_images/geo.jpeg";
import marjatat from "./popup_images/marjatat.jpeg";

import rikospaikka from "./popup_images/rikospaikka1.jpeg";
import laos from "./popup_images/laos.jpeg";
import stlaurent from "./popup_images/stlaurent.jpeg";
import positron from "./popup_images/positron.jpeg";
import makeway from "./popup_images/makeway.jpeg";
import nigel from "./popup_images/nigel.jpeg";

import hytti from "./popup_images/hytti1.jpeg";
import dokkarit from "./popup_images/dokkarit.jpeg";

import beshar from "./popup_images/beshar.jpg";
import anton from "./popup_images/anton.jpg";
import kristo from "./popup_images/kristo.jpg";
import didi from "./popup_images/didi.jpeg";
import monica from "./popup_images/monica.jpeg";
import mataleena from "./popup_images/mataleena.jpeg";

import slobros from "./popup_images/slobrosmenu.jpeg";
import ironpig from "./popup_images/ironpigmenu.png";

import vanhis from "./popup_images/vanhis.jpeg";
import paalue from "./popup_images/paalue.png";

import mylly from "./Mylly.png";

export const map_content = {
  kartano: {
    artists: [
      {
        image: kristo,
        name: "Kristo Saarikoski",
        contact: "kristo.saarikoski@gmail.com",
        text: [
          "Kristo Saarikoski (s. 1992) on taidemaalariopiskelija Vapaasta taidekoulusta. Saarikosken kiinnostus kuvataiteeseen kumpuaa vuosien takaa graffiti-taiteen parista, niin kulttuurin seuraajan kuin toimijankin osalta. Vuosien aikana kiinnostus on laajentunut mm. modernismin ja abstraktin taiteen suuntauksiin, joista Saarikoski on ammentanut omiin teoksiinsa elementtejä.",
          "Saarikosken teoksissa todenmukaiset aiheet usein sekoittuvat uudelleen ajateltuihin ympäristöihin. Etääntyminen perspektiiviä ja muotoja koskevista opeista tuo vapauden ajatella toisin. Saarikosken teoksia inspiroi myös kiinnostus erilaisten materiaalien käytöstä maalauksissa.",
          "Kulttuurijärvi Festivaaleilla Saarikoski esittelee 3-osaisen teoksen, jossa maalauspohjina toimivat pleksi- ja peililasit. Teoksen materiaalien heijastuvuus sekä läpikuultavuus tuovat teoksen osaksi Kulttuurijärven ympäristöä.",
        ],
      },
      {
        image: beshar,
        name: "Mia Beshar",
        contact: "miia.beshar@gmail.com",
        insta: "https://www.instagram.com/tisutvaasit/",
        web: "https://www.tisutvaasit.com",
        text: [
          "Tisut juhlistavat hiljaista estetiikkaa ja luonnon muotoja, pienellä viittauksella klassiseen kuvanveistoon. Teokset tehdään tilauksesta, mikä takaa jokaisen vaasin ainutlaatuisuuden.",
          "Kuva-artesaani Miia Besharin käsialaa olevat vaasit syntyvät kotistudiolla Punavuoressa. Vaasien matka alkoi vuonna 2017, ja ovat matkan varrella löytäneet nykyisen muotonsa. Tisujen lisäksi perheeseen kuuluu Peput, Globes Tisut ja Hips.",
        ],
      },
      {
        image: didi,
        name: "Didi Ng",
        contact: "didi@dd-ng.com",
        insta: "https://www.instagram.com/dd_ng",
        web: "https://dd-ng.com",
        text: [
          "Didi Ng on Hong Kongista kotoisin oleva huonekalumuotoilija, joka opiskelee sisustusarkkitehtuuria Aalto Yliopistossa. Hän työskentelee huonekalumuotoilun, tilasuunnittelun ja taiteen aloilla. Hän keskittyy töissään tutkailemaan abstraktien muotojen ja geometrian tasapainoa, luoden oman graafisen kielensä. Materiaalien, tekstuurien, muotojen, värien ja tilan välinen kontrasti muodostaa harmonisia kokonaisuuksia.",
          "Kansainvälisen taustansa vaikutuksesta hänen inspiraationsa kumpuaa eri kulttuureiden lisäksi urbaanin ympäristön ja luonnon yhdistelmästä. Hänen päämääränsä on yhdistää ihmiset ja esineet kontekstuaalisen luomisen kautta, jotta yleisö voi nauttia hänen töidensä sisältämästä energiavirrasta.",
        ],
      },
      {
        image: mataleena,
        name: "Mataleena",
        contact: "kastanjoita@gmail.com",
        quote: true,
        text: [
          '"Minua kiehtoo ihmisen ja luonnon suhde, ennen kaikkea se, mitä voimme oppia luonnosta. Luonto sopeutuu ja mukautuu loogisesti. Erityisesti puut inspiroivat minua. Myös kokemus erillisyydestä ja toisaalta yhteenkuulumisesta kaikuvat tekemisessäni. Maalaan yhdistellen muistoistani, intuitiostani ja tunnetiloistani lähtöisin. Taiteilijana haluan etsiä monipuolisesti rajojani ja hyödyntää erilaisia tekniikoita. Maalaankin vaihtelevasti mm. öljyllä, temperalla, akryylilla ja guassilla. En halua lokeroitua tiettyyn tyyliin, sillä olennaista on kunkin maalauksen oma maailma. Koska maalaus itsessään on aina se tärkein, haluan taiteilijana pysyä anonyyminä."',
        ],
      },
      {
        image: monica,
        name: "Monica Väre",
        insta: "https://www.instagram.com/monica.vare",
        quote: true,
        text: [
          '"Olen viettänyt koko ikäni tehden erilaisia käsitöitä ja työskennellyt luovien alojen parissa, mm. lavastemaalarina ja lavastajana, sekä valo- ja videokuvaajana. Viime vuosina kuvataide ja varsinkin maalaaminen on muodostanut vahvan keskiön elämääni ja opiskelen tällä hetkellä klassista kuvataidetta Repin-instituutissa. Maalaamisella luon siltaa ulkomaailman ja oman sisäisen maailmani välille.  Maalatessa voi keskustella muotojen, värien ja hahmojen kanssa ja yrittää tavoitella keskustelua kankaalle. Minua puhuttelee erityisesti maalauksista välittyvät tarinat joissa näkyy taiteilijan draamantaju kuvata ihmisyys hirveänä ja kauniina, ja tähän pyrin myös omissa töissäni. Maalaaminen merkitsee minulle sallimista leikitellä vapaasti paitsi omilla, myös katsojan tunnetiloilla."',
        ],
      },
      {
        image: anton,
        name: "Anton Mikkonen",
        contact: "antonmikkonen.studio@gmail.com",
        insta: "https://www.instagram.com/antonmtmikkonen",
        web: "https://www.antonmikkonen.com",
        text: [
          "Anton Mikkonen on Järvenpäästä kotoisin oleva lupaava muotoilija, joka opiskelee Aalto Yliopistossa nykymuotoilua. Antonin luomukset tasapainottelevat huonekalujen, veistosten ja taiteen välillä. Hänellä on käytännönläheinen lähestymistapa suunnitteluprosessiin ja uteliaisuus erilaisia ​​materiaaleja, tekniikoita ja menetelmiä kohtaan, sekä suunnittelussa että valmistuksessa.",
          "Anton kertoo työnsä ytimen lepäävän yksinkertaisessa, mutta toimivassa suunnittelussa, tavoitteenaan luoda kestäviä, sekä ajattomia kappaleita, silmiinpistävällä estetiikalla.",
          "  Antonin teoksia on ollut näytillä korkean profiilin näyttelyissä mm. Pure Talents Contest - IMM Cologne näyttelyssä ja 'Against the Burn': Finland at Virtual Design Destination 2020, yhteistyössä tanskalaisen online galleria Adornon kanssa, osana London Design Festivalia.  Vaikutusvaltainen arkkitehtuuri-ja design lehti Blueprint Magazine listasi Antonin heidän vuotuisessa ’Ones to Watch’ artikkelissa, hänen valmistumisensa jälkeen.",
        ],
      },
      {
        image: marjatat,
        name: "Paskan Marjatat",
        insta: "https://www.instagram.com/paskanmarjatat",
        text: [
          "Kaksi järvenpääläissyntyistä Marjattaa piirtelee niskat limassa ja perseet olalla suomenkielen kauneimpia/kauheimpia sanontoja ja ilmauksia.",
          "Paskanmarjattojen taidetta voit ihailla lisää instagramissa",
          "@paskanmarjatat",
        ],
      },
    ],
    //when: "To-La 18:00-00:00",
    header: "Design näyttely",
    whens: ["To 18:00-24:00", "Pe 17:00-01:00", "La 14:00-16:00"],
    image: vanhis,
    //header: "Rikospaikka",
    text:
      "Koko festareiden ajan Vanhiksen kartanossa voi ihailla Järvenpään ja lähialueiden taitajien design- ja taideteoksia.",
    list: {
      header: "Näyttelyn taitelijat:",
      elems: [
        "Kristo Saarikoski",
        "Mia Beshar",
        "Didi ng",
        "Mataleena",
        "Monica Väre",
        "Anton Mikkonen",
        "Paskan Marjatat",
      ],
    },
  },
  ruoka: {
    artists: [
      {
        image: ironpig,
        name: "Iron Pig Food",
        text: [
          "IRON PIG FOOD COMPANÜ feat. JOHNNY burgereineen ja kylkiäisineen pitävät huolen siitä, että Kulttuurijärvi Festarin kävijät saavat kulttuurinälkänsä lisäksi myös ruokanälkänsä tyydytettyä.",
        ],
      },
      {
        image: slobros,
        name: "SLOBros",
        text: [
          "Cittaristakin tuttu SLO BROS löytyy myös Kulttuurijärvi Festareilta! SLO Brosin tacot ovat alusta asti käsintehtyjä, ja käytössä on vain laadukkaimmat raaka-aineet. Veljesten ruoat ja meininki huokuu Kalifornian välitöntä rentoutta.",
        ],
      },
    ],
    whens: ["To 19:00-24:00", "Pe 17:00-24:00", "La 17:00-24:00"],
    header: "Ruoka-alue",
    text:
      "Ruokavaunuista ja anniskelupisteiltä tarjoillaan herkkuja nälkäislle ja janoisille.",
  },
  paaalue: {
    artists: [
      {
        image: geo,
        name: "Geo",
        insta: "https://www.instagram.com/geo_band_official/",
        spotify:
          "https://open.spotify.com/artist/1vKMIsAuNzFvb8k7DIpzh1?si=HtoQ6i8QTiyIa9V0FLEXqw&dl_branch=1",
        text: [
          "Syna- ja indie rockin vehreillä laitumilla vuodesta 2015 lähtien käyskennellyt GEO yhdistelee musiikissaan mahtipontista orkesterimaalailua akustiseen tunnelmointiin. Yhtye on vuoteen 2021 mennessä ehtinyt julkaisemaan omakustanteisesti yhden EP:n ja yhden pitkäsoiton. Turkulais-helsinkiläinen kolmikko on ehtinyt esiintyä kotiimaan lisäksi myös Venäjällä.",
        ],
      },
      {
        image: bazaar,
        name: "Astral Bazaar",
        spotify:
          "https://open.spotify.com/artist/3SWJqNmex4TiAkT3yCtQx4?si=G_05ZC4rRF-nQTBk_v6Fnw&dl_branch=1",
        insta: "https://www.instagram.com/astralbazaar_band/",
        text: [
          "Astral Bazaar on pk-seudulta ponnistava psykedeelistä rockia soittava yhtye, jonka musiikissa yhdistyvät eri tyylilajien vaikutteet. Toisen pitkäsoittonsa, 'A Sudden Realization', hiljattain julkaissut ja Suomea jo joitain vuosia kiertänyt yhtye johdattaa kuuntelijansa transsiin eksoottisella jamittelulla ja improvisaatiolla, joten tätä keikkaa ei kannata jättää väliin!",
        ],
      },
      {
        image: bullsonp,
        name: "Bulls On Parade",
        text: [
          "Rage Against The Machine:n tuotantoa tulkitseva coverbändi Bulls on Parade perustettiin vuosien unelmoinnin jälkeen kaveriporukan saunaillassa, bissen ja purilaisten äärellä. Kokoonpanossa kuullaan ja nähdään Järvenpään omat pojat Eero ja Inttu, Someron lupaus (ja uhka) Juha… sekä tietysti Pekka, joka entisenä helikopterilääkärinä taatusti tietää miten keppiä heilutetaan. “Bam, here’s the plan, motherf**k Uncle Sam!”",
        ],
      },
      {
        image: peltonen,
        name: "Entäs Peltonen?",
        spotify:
          "https://open.spotify.com/artist/5yZcaFctThsrvk6uPOtZ8U?si=tkd-j8QBSyGsrSyd2kBytg&dl_branch=1",
        insta: "https://www.instagram.com/entaspeltonen/",
        text: [
          "Toisen albuminsa valmistelun vuoksi harvakseen keikkaileva Entäs Peltonen? soittaa suomenkielistä kitarapoppia. 2018 ilmestyneen debyyttialbuminsa jälkeen yhtye on julkaissut tasaisin väliajoin maistiaisia monisävyisempään suuntaan hiljalleen siirtyvästä biisimateriaalista.",
          "Viimeisimpänä yhtye julkaisi vuonna 2020 singlet Keltainen kuu ja Verona, jotka tehtiin tulevan albumin tapaan Lammaskallion Audion studiolla yhteistyössä tuottaja-äänittäjä Sakari Kumpulan kanssa. Entäs Peltonen? on opittu vuosien varrella tuntemaan ennen kaikkea hyväntuulisesta livemeiningistään ja leppoisasta tunnelmasta!",
        ],
      },
      {
        image: pahis,
        name: "Paha vaanii",
        text: [
          "Pohjolan epäilemättä tiukin kotimaisten klassikkohittien soittajaduo eli Paha Vaanii on niittänyt mainetta erityisesti Siltasen kesäsunnuntai-illoissa, mutta myös lukuisilla muilla Helsingin kuumilla keikkamestoilla ja rantalavoilla. Musiikkiskaala koostuu suomalaisista klassikoista tähän päivään - mukaan mahtuu niin uutuudet, unohtuneet helmet ja outoudet genreen katsomatta. Pahis takaa tiukan meiningin ja hikikarpalot otsalle!",
        ],
      },
      {
        image: rikospaikka,
        name: "Rikospaikka DJ:t",
        text: [
          "Legendaarisista elektronisen musiikin bileistään tunnettu DJ-kollektiivi tekee paluun Kulttuurijärvi Festareilla PALUU RIKOSPAIKALLE klubillaan!",
        ],
      },
      {
        image: positron,
        name: "Positron",
        text: [
          "Paluu Rikospaikalle -tapahtumassa kuullaan ensimmäisenä Positronia. Hänen ensimmäiset setit Järvenpäässä kuultiin vuonna 1996 paikallisen hotellin yökerhon räppiklubilla. Siitä matka on jatkunut Esperancen ja Wiiman kautta legendaarisen (vanhan) Yanon DJ-koppiin. Sittemmin helsinkiläistynyt Positron on pitänyt vakioiltaa myös edesmenneessä Mbarissa ja tällä hetkellä hänet löytää parhaiten soittamasta levyjä Ompusta, jossa hän pitää päämajaansa. Positronin setistä ei voi kuin tulla hyvälle tuulelle. Rikospaikka suosittelee!",
        ],
      },
      {
        image: laos,
        name: "L.A.O.S (Large Amount Of Soul)",
        text: [
          "L.A.O.S on Helsinkiläinen yksi pitkäikäisimmistä ja kansainvälisesti menestyneimmistä Drum&Bass ryhmistä Suomesta. Heidän musiikkiaan on julkaistu mm. levymerkeiltä kuten Hospital Records, Viper, Liquicity ja Spearhead. Ryhmä on ollut aktiivinen jo 2000-luvun alkupuolesta saakka ja tunnetaan myös klubikonseptien kuten Standard:n takaa. Viimeisimmät julkaisut ovat myös päätyneet lukuisille soittolistoille ja pitäneet mm. YleX:n uudenmusiikin listan ykköspaikkaa (Bright Lights).",
        ],
      },
      {
        image: stlaurent,
        name: "St.Laurent",
        text: [
          "Tämä pitkän linjan DJ on palannut Rikospaikalle jo useamman kerran. Hänet tunnetaan parhaiten sielukkaista Drum&Bass seteistään, joihin heitellään mukaan haisunäätäaromilla siveltyjä pommeja. Viime aikoina St. Laurent DnB on kokeillut siipiään myös tuottajana ja vastaanottamamme pikasähkeen mukaan näihin bileisiin on myös valmisteltu uutta materiaalia.",
        ],
      },
      {
        image: nigel,
        name: "DJ Nigel",
        text: [
          "Aivan ensimmäisellä, sekä usealla muulla Rikospaikalla soittamassa ollut DJ Nigel nähdään ja kuullaan myös Paluu Rikospaikalle -tapahtumassa. DJ Nigel on viimeiset vuodet hakenut musiikki-inspiraatiota Berliinissä ja Espanjassa. Ibizan viimeisimmät kuulumiset on jo tänäkin kesänä tullut tarkistettua. Tätä settiä etenkin Rikospaikan DJ:t odottavat kovasti ja Nigel onkin lupaillut, että jykevällä housella laitetaan tälläkin kertaa lattia tuleen.",
        ],
      },
      {
        image: makeway,
        name: "Makeway Sounds",
        text: [
          "Kulttuurijärvi Festareiden Paluu Rikospaikalle -tapahtuman äänentoistosta ja lavan valoista vastaa MAKEWAY SOUNDS. Tämän muutaman järvenpääläisen äänentoistoistojärjestelmistä ja DJ-kulttuurista innostuneen porukan tarina alkoi vuonna 2008, kun he alkoivat rakentamaan erilaisia hifi-luokan studio- ja PA-settejä. Pikakelaus eteenpäin ja Makeway Sounds on ollut järjestämässä tekniikkaa sellaisiin tapahtumiin kuin Kallio Block Party, Rikospaikka , Rantsu Radio ja Uuno Viidakossa. Heidän äänentoistoon ovat luottaneet sellaiset artistit kuin Gasellit, Raappana, Solonen & Kosola sekä Rikospaikka.",
          "Äänentoiston suunnittelussa Makeway Sounds on tinkimätön: yhtäkään oikoreittiä ei ole otettu, vaan aina on edetty sillä, että vain paras kelpaa. Paluu Rikospaikalle -tapahtumassakin kuultava SoundSystem on suunniteltu vartavasten elektroonista musiikkia varten, jossa vaaditaan ulottuvaa basson toistoa. Se on täysin torvi-tekniikalla toteutettu äärimmäisen raju, mutta ääneltään tasapainoisen silkkinen prosessoriohjattu 5-tie setti jossa yhdistyy hifi-tason äänentoisto PA käyttöön. Mainittakoon tästä setistä se, että sen suunnittelu ja toteutus kesti noin 6 vuotta.",
          "Makeway Soundsin missio on tukea paikallista DJ-kulttuuria, rakkaudesta lajiin.",
        ],
      },
    ],
    //when: "To-La 18:00-00:00",
    header: "Pääalueen keikat",
    whens: ["Pe 18:00-24:00 - Ilmainen", "La 18:00-24:00 - Loppuunmyyty"],
    image: paalue,
    //header: "Rikospaikka",
    text:
      "Perjantaina ja lauantaina Kulttuurijärvi festivaaleilla kuullaa livemusiikkia bändien ja tiskijukkien muodossa.",
    list: {
      header: "Esiintyjät:",
      elems: [
        "Geo",
        "Astral Bazaar",
        "Bulls On Parade",
        "Entäs Peltonen?",
        "Paha Vaanii",
        "Rikospaikka-DJ:t",
        "Positron",
        "L.A.O.S",
        "St. Laurent",
        "DJ Nigel",
      ],
    },
  },
  kino: {
    artists: [
      {
        image: hytti,
        name: "Kristo Saarikoski",
        web:
          "https://holvi.com/shop/studio123jpaa/product/1cc6896653d22e097fbb153db9460a58/",
        web_text: "OSTA LIPUT TÄSTÄ",
        text: [
          "Kulttuurijärvi & Studio 123 ylpeänä esittävät: Ennakkonäytös: HYTTI NRO 6 Näe ensimmäisten joukossa ainutlaatuisessa kulttuuriympäristössä Cannesin elokuvajuhlien Grand Prix'lla palkittu elokuva.",
          "Juho Kuosmasen Hytti nro 6 vie unohtumattomalle junamatkalle arktisten neuvostomaisemien halki. Sydänsurujaan Moskovasta Murmanskiin pakeneva Laura (Seidi Haarla) saa hyttikaverikseen kovapintaisen kaivostyöntekijän Ljohan (Juri Borisov). Hienovaraisen huumorin värittämä kohtaaminen täyteen ahdetun junan erikoislaatuisessa maailmassa saa kummatkin matkaajat näkemään myös itsensä uusin silmin.",
          "Kuosmasen lämmin ja vilpitön road movie on syntynyt Rosa Liksomin Finlandia-palkitun romaanin innoittamana.",
        ],
      },
      {
        image: dokkarit,
        name: "Dokkarit",
        text: [
          "HALPOJA TAHROJA - Marlena Martikainen",
          "Marlena joutuu keväällä 2020 lomautetuksi neljän seinän sisään. Hätää uuden työn löytämiseksi ei tunnu olevan, saatikka motivaatiota rehkiä rahan eteen. Millaisia summia liikkuu käytettyjen pikkuhousujen myynnissä?\n\n",
          "SOUL PARTY / B’S & HONEY - Soul Skateboards",
          "@soulskateboards_official",
          "Soul Skateboards on Järvenpäässä vuosituhannen vauhteessa skeittauksen yhteentuomien ystävysten crew. Soul Skateboardsin ensimmäinen leffa on vinyylilevyjen tavoin A- ja B-puoleen jaettu Soul Party / B’s & Honey.",
          "Pääasiassa kesän 2020 aikana kuvatuissa montage-tyylisissä pätkissä esiintyy sekalainen kokoonpano frendejä radan varrelta.\n\n",
          "THE SHAPE OF THE WAVE - Iain Macintosh",
          "Moog Minimoog-syntetisaattoreiden tultua suosituiksi 60-luvun puolivälissä, syntetisaattoreilllla on ollut vahva ja kiistaton vaikutus popkulttuuriin. Mikä näissä mystisissä instrumenteissa, jotka moni tunnistaa mutta vain kourallinen ihmisistä ymmärtää, oikein kiehtoo? The Shape of The Wave on katsaus analogisten syntikoiden sekä niiden vannoutuneiden harrastajien maailmaan.\n\n",
          "POIKIEN PUHELIN - Laura Rantanen",
          "Tiesitkö, että murrosikä jatkuu ikuisesti? Poikien puhelin on dokumentaarinen lyhytelokuva ylisukupolvisuudesta ja sosiaalisesti rakennetusta maskuliinisuudesta. Dokumentti perustuu Väestöliiton Poikien Puhelimessa, alle 20-vuotiaille pojille ja nuorille miehille suunnatussa puhelinpalvelussa, käytyihin keskusteluihin.\n\n",
          "LIMINAALI & COMMUNITAS - Laura Rantanen",
          "Hämärän laskeuduttua mies kulkee pitkin maa- ja moottoriteiden varsia keräten syömäkelpoisia, autojen alle jääneitä eläimiä. Liminaali & Communitas on kokeellinen dokumentaarinen lyhytelokuva roadkill-eläimien hyödyntämisestä. Elokuva tutkii elämän ja kuoleman välistä rajaa sekä ihmisen rakentaman maailman reunamia.\n\n",
          "JÄRVENPÄÄ - TEOLLISUUDEN, OPIN JA TAITEEN AHJO - Usko Kemppi",
          "Järvenpään kauppalan esittelyelokuva vuodelta 1953. Filmillä nähdään mm. Vanhankylän kartanon tammisto, sisäkuvia eri tehtailta, myymälä, leipomo, ravintola, autohuoltoasema, kouluja, puistoja, puutarhoja sekä muita järvenpääläisiä paikkoja sekä tietysti Ainola ja Sibelius.\n\n",
        ],
      },
    ],
    //when: "To-La 18:00-00:00",
    header: "Teatteripubi (Ulkoilmakino)",
    whens: ["To 20:30 (Hytti nro 6)", "Pe 20:00 (Lyhytdokkareita)"],
    image: mylly,
    //header: "Rikospaikka",
    text:
      "Livemusan ja taiteen lisäksi Kulttuurijärvi Festareiden ohjelmassa on myös elokuva ja dokkareita.",
    // list: {
    //   header: "Sisältö",
    //   elems: [
    //     "Hytti nro 6 - liput myynnissä alempana",
    //     "Marlena Martikainen: Halpoja Tahroja",
    //     "Soul Skateboards: Soul Party / B’s & Honey",
    //     "Iain Macintosh: The Shape of the Wave",
    //     "Laura Rantanen: Poikien puhelin",
    //     "Laura Rantanen: Liminaali & Communitas",
    //     "Usko Kemppi: Järvenpää – teollisuuden, opin ja taiteen ahjo",
    //   ],
    // },
  },
};

export const popup_content = {
  peltonen: {
    when: "Pe 21:00",
    //header: "Entäs peltonen",
    text:
      "Toisen albuminsa valmistelun vuoksi harvakseen keikkaileva Entäs Peltonen? soittaa suomenkielistä kitarapoppia. 2018 ilmestyneen debyyttialbuminsa jälkeen yhtye on julkaissut tasaisin väliajoin maistiaisia monisävyisempään suuntaan hiljalleen siirtyvästä biisimateriaalista.\n\n Viimeisimpänä yhtye julkaisi vuonna 2020 singlet Keltainen kuu ja Verona, jotka tehtiin tulevan albumin tapaan Lammaskallion Audion studiolla yhteistyössä tuottaja-äänittäjä Sakari Kumpulan kanssa. Entäs Peltonen? on opittu vuosien varrella tuntemaan ennen kaikkea hyväntuulisesta livemeiningistään ja leppoisasta tunnelmasta!",
    image: peltonen,
    spotify:
      "https://open.spotify.com/artist/5yZcaFctThsrvk6uPOtZ8U?si=tkd-j8QBSyGsrSyd2kBytg&dl_branch=1",
    insta: "https://www.instagram.com/entaspeltonen/",
  },
  bazaar: {
    when: "Pe 19:00",
    //header: "Astral Bazaar",
    text:
      'Astral Bazaar on pk-seudulta ponnistava psykedeelistä rockia soittava yhtye, jonka musiikissa yhdistyvät eri tyylilajien vaikutteet. Toisen pitkäsoittonsa, "A Sudden Realization", hiljattain julkaissut ja Suomea jo joitain vuosia kiertänyt yhtye johdattaa kuuntelijansa transsiin eksoottisella jamittelulla ja improvisaatiolla, joten tätä keikkaa ei kannata jättää väliin!',
    image: bazaar,
    spotify:
      "https://open.spotify.com/artist/3SWJqNmex4TiAkT3yCtQx4?si=G_05ZC4rRF-nQTBk_v6Fnw&dl_branch=1",
    insta: "https://www.instagram.com/astralbazaar_band/",
  },
  bullsonp: {
    when: "Pe 20:00",
    //header: "Bulls On Parade",
    text:
      "Rage Against The Machine:n tuotantoa tulkitseva coverbändi Bulls on Parade perustettiin vuosien unelmoinnin jälkeen kaveriporukan saunaillassa, bissen ja purilaisten äärellä. Kokoonpanossa kuullaan ja nähdään Järvenpään omat pojat Eero ja Inttu, Someron lupaus (ja uhka) Juha… sekä tietysti Pekka, joka entisenä helikopterilääkärinä taatusti tietää miten keppiä heilutetaan. “Bam, here’s the plan, motherf**k Uncle Sam!”",
    image: bullsonp,
  },
  geo: {
    when: "Pe 18:00",
    //header: "Geo",
    text:
      "Syna- ja indie rockin vehreillä laitumilla vuodesta 2015 lähtien käyskennellyt GEO yhdistelee musiikissaan mahtipontista orkesterimaalailua akustiseen tunnelmointiin. Yhtye on vuoteen 2021 mennessä ehtinyt julkaisemaan omakustanteisesti yhden EP:n ja yhden pitkäsoiton. Turkulais-helsinkiläinen kolmikko on ehtinyt esiintyä kotiimaan lisäksi myös Venäjällä.",
    image: geo,
    insta: "https://www.instagram.com/geo_band_official/",
    spotify:
      "https://open.spotify.com/artist/1vKMIsAuNzFvb8k7DIpzh1?si=HtoQ6i8QTiyIa9V0FLEXqw&dl_branch=1",
  },
  pahis: {
    when: "Pe 22:00",
    //header: "Paha Vaanii",
    text:
      "Pohjolan epäilemättä tiukin kotimaisten klassikkohittien soittajaduo eli Paha Vaanii on niittänyt mainetta erityisesti Siltasen kesäsunnuntai-illoissa, mutta myös lukuisilla muilla Helsingin kuumilla keikkamestoilla ja rantalavoilla. Musiikkiskaala koostuu suomalaisista klassikoista tähän päivään - mukaan mahtuu niin uutuudet, unohtuneet helmet ja outoudet genreen katsomatta. Pahis takaa tiukan meiningin ja hikikarpalot otsalle!",
    image: pahis,
  },
  rikospaikka: {
    when: "La 18:00-00:00",
    //header: "Rikospaikka",
    text:
      "Legendaarisista elektronisen musiikin bileistään tunnettu DJ-kollektiivi tekee paluun Kulttuurijärvi Festareilla PALUU RIKOSPAIKALLE klubillaan! Klubin tapahtuma-alue on Vanhiksen Kartanon osittain katettu ulkoterassi-alue, myös sisätilat kuuluvat alueeseen. Varaudu siis sään mukaisesti ulkokeikoille - mielummin liikaa vaatetta ja tarvittaessa sadekamat! Kartanon tilat ovat anniskelualuetta.",
    image: rikospaikka,
    link:
      "https://billetto.fi/e/kulttuurijarvi-festivaali-rikospaikka-liput-565197",
    soldout: true,
    list: {
      header: "LINE-UP:",
      elems: [
        "Rikospaikka-DJ:t",
        "Positron",
        "St. Laurent",
        "DJ Nigel",
        "L.A.O.S",
      ],
    },
  },
  hytti: {
    when: "To 20:30",
    text:
      "Cannesin elokuvajuhlien Grand Prix'lla palkittu Juho Kuosmasen elokuva Hytti nro 6 vie unohtumattomalle junamatkalle arktisten neuvostomaisemien halki. Sydänsurujaan Moskovasta Murmanskiin pakeneva Laura (Seidi Haarla) saa hyttikaverikseen kovapintaisen kaivostyöntekijän Ljohan (Juri Borisov). Lauran odotukset rauhoittavasta etsikkomatkasta karisevat kerta heitolla, kun Ljoha juo itsensä äänekkääseen humalaan heti ensimmäisenä iltana. Alun yhteentörmäyksen jälkeen eriparinen kaksikko alkaa kuitenkin löytää toisistaan jotakin yhteistä ja odottamatonta. Hienovaraisen huumorin värittämä kohtaaminen täyteen ahdetun junan erikoislaatuisessa maailmassa saa kummatkin matkaajat näkemään myös itsensä uusin silmin. Hymyilevä mies -elokuvallaan kansainväliseen menestykseen nousseen Kuosmasen lämmin ja vilpitön road movie on syntynyt Rosa Liksomin Finlandia-palkitun romaanin innoittamana.",
    image: hytti,
    link:
      "https://holvi.com/shop/studio123jpaa/product/1cc6896653d22e097fbb153db9460a58/",
  },
  dokkarit: {
    texts: [
      "HALPOJA TAHROJA - Marlena Martikainen",
      "Marlena joutuu keväällä 2020 lomautetuksi neljän seinän sisään. Hätää uuden työn löytämiseksi ei tunnu olevan, saatikka motivaatiota rehkiä rahan eteen. Millaisia summia liikkuu käytettyjen pikkuhousujen myynnissä?\n\n",
      "SOUL PARTY / B’S & HONEY - Soul Skateboards",
      "@soulskateboards_official",
      "Soul Skateboards on Järvenpäässä vuosituhannen vauhteessa skeittauksen yhteentuomien ystävysten crew. Soul Skateboardsin ensimmäinen leffa on vinyylilevyjen tavoin A- ja B-puoleen jaettu Soul Party / B’s & Honey.",
      "Pääasiassa kesän 2020 aikana kuvatuissa montage-tyylisissä pätkissä esiintyy sekalainen kokoonpano frendejä radan varrelta.\n\n",
      "THE SHAPE OF THE WAVE - Iain Macintosh",
      "Moog Minimoog-syntetisaattoreiden tultua suosituiksi 60-luvun puolivälissä, syntetisaattoreilllla on ollut vahva ja kiistaton vaikutus popkulttuuriin. Mikä näissä mystisissä instrumenteissa, jotka moni tunnistaa mutta vain kourallinen ihmisistä ymmärtää, oikein kiehtoo? The Shape of The Wave on katsaus analogisten syntikoiden sekä niiden vannoutuneiden harrastajien maailmaan.\n\n",
      "POIKIEN PUHELIN - Laura Rantanen",
      "Tiesitkö, että murrosikä jatkuu ikuisesti? Poikien puhelin on dokumentaarinen lyhytelokuva ylisukupolvisuudesta ja sosiaalisesti rakennetusta maskuliinisuudesta. Dokumentti perustuu Väestöliiton Poikien Puhelimessa, alle 20-vuotiaille pojille ja nuorille miehille suunnatussa puhelinpalvelussa, käytyihin keskusteluihin.\n\n",
      "LIMINAALI & COMMUNITAS - Laura Rantanen",
      "Hämärän laskeuduttua mies kulkee pitkin maa- ja moottoriteiden varsia keräten syömäkelpoisia, autojen alle jääneitä eläimiä. Liminaali & Communitas on kokeellinen dokumentaarinen lyhytelokuva roadkill-eläimien hyödyntämisestä. Elokuva tutkii elämän ja kuoleman välistä rajaa sekä ihmisen rakentaman maailman reunamia.\n\n",
      "JÄRVENPÄÄ - TEOLLISUUDEN, OPIN JA TAITEEN AHJO - Usko Kemppi",
      "Järvenpään kauppalan esittelyelokuva vuodelta 1953. Filmillä nähdään mm. Vanhankylän kartanon tammisto, sisäkuvia eri tehtailta, myymälä, leipomo, ravintola, autohuoltoasema, kouluja, puistoja, puutarhoja sekä muita järvenpääläisiä paikkoja sekä tietysti Ainola ja Sibelius.\n\n",
    ],

    header: "Lyhytdokumentteja",
    when: ["Pe 20:00"],
    image: dokkarit,
  },
  default: {
    when: "Ei koskaan",
    header: "Anon",
    text: "Lorem ipsum",
    image: peltonen,
  },
};

export const hasIdContent = (id) =>
  [map_content, popup_content].findIndex((c) => !!c[id]) > -1;
