import { useState } from "react";
import "./popup1.scss";

import useWindowDimensions from "./useWindowDimensions";
import { popup_content } from "./popup_content.js";
import spotify from "./icons/spotify.png";
import insta from "./icons/insta-vih.png";

export default function Popup1({ content: _content }) {
  const { width, height } = useWindowDimensions();
  const [expandedIdx, setExpandedIdx] = useState(-1);
  const imageHeight = width > 800 ? 650 : 270;
  const iframeUrl = `https://www.facebook.com/plugins/video.php?height=${imageHeight}&href=https%3A%2F%2Fwww.facebook.com%2FKulttuurijarvi%2Fvideos%2F399818257938928%2F&show_text=false&width=${width}&t=0`;
  const paddingBottom = `${(imageHeight / width) * 100}%`;

  const content = _content ?? popup_content.default;

  const expand = (e, idx) => {
    e.preventDefault();
    e.stopPropagation();
    const newIdx = expandedIdx === idx ? -1 : idx;
    setExpandedIdx(newIdx);
  };

  // {!!content?.image || !!content?.video ? (
  //   <div
  //     className="zpopup__image"
  //     style={{
  //       backgroundImage: `url(${content?.image ?? ""})`,
  //     }}
  //   >
  //     {!!content?.video ? (
  //       <div className="fb-wrapper" style={{ paddingBottom }}>
  //         <iframe src={iframeUrl} width="100%" height={imageHeight} />
  //       </div>
  //     ) : null}
  //   </div>
  // ) : null}

  const textToInsta = (t, idx, quote = false) =>
    t.includes("@") ? (
      <a
        className="instalink"
        href={t.replace("@", "https://www.instagram.com/")}
        target="_blank"
        rel="noreferrer"
        key={idx}
      >
        {t}
      </a>
    ) : (
      <p key={idx} style={{ fontStyle: quote ? "italic" : "normal" }}>
        {t}
      </p>
    );

  return (
    <div className="popup__frame">
      {!!content?.image && (
        <img className="popup__frame__headerimage" src={content?.image} />
      )}
      <div className="popup__frame__content">
        {!!content?.header ? <h1>{content.header}</h1> : null}
        {!!content.whens && (
          <div className="whens_bar">
            {content.whens &&
              content.whens.map((w, idx) => (
                <h3 key={idx} className="whens">
                  {w}
                </h3>
              ))}
          </div>
        )}
        {content.when && (
          <div className="when_bar">
            <h3 className="when_time">{content.when}</h3>
            {!!content?.spotify && (
              <a href={content.spotify} target="_blank" rel="noreferrer">
                <img className="some" src={spotify} />
              </a>
            )}
            {!!content.insta && (
              <a href={content.insta} target="_blank" rel="noreferrer">
                <img className="some" src={insta} />
              </a>
            )}
          </div>
        )}
        {content.soldout && <p className="soldout">LOPPUUNMYYTY</p>}
        {!content.soldout && !!content.link && (
          <a
            className="buy_link_popup"
            href={content.link}
            target="_blank"
            rel="noreferrer"
          >
            OSTA LIPUT TÄSTÄ
          </a>
        )}
        {!!content.text && <p>{content.text}</p>}
        {!!content.texts && content.texts.map((t, idx) => textToInsta(t, idx))}
        {!!content.list && (
          <>
            <p>{content.list.header}</p>
            <ul>
              {content.list.elems.map((item, idx) => (
                <li key={idx}>{item}</li>
              ))}
            </ul>
          </>
        )}
      </div>
      {content?.artists?.map((artist, idx) => (
        <div
          key={artist.name}
          className={`artists__container expanded ${
            idx === expandedIdx ? "expanded" : ""
          }`}
          //onClick={(e) => expand(e, idx)}
        >
          {(!!artist.image || !!artist.name) && (
              <div className="artists__container__header">
                {!!artist.image && (
                  <img className="popup__images" src={artist.image} />
                )}
                {!!artist.name && (
                  <div className="artist__name">
                    <h1>{artist.name}</h1>
                  </div>
                )}
              </div>
            )}
          <div className={`artists__content`}>
            <div
              className="artist_contact"
              onClick={(e) => e.stopPropagation()}
            >
              {artist.contact && (
                <a
                  href={`mailto:${artist.contact}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {artist.contact}
                </a>
              )}
              {!!artist.web && (
                <a
                  className="web"
                  href={artist.web}
                  target="_blank"
                  rel="noreferrer"
                >
                  {artist.web_text ??
                    artist.web.replace("https://", "").replace("www.", "")}
                </a>
              )}
              {!!artist.spotify && (
                <a
                  href={artist.spotify}
                  className="artist_some"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={spotify} />
                </a>
              )}
              {!!artist.insta && (
                <a
                  href={artist.insta}
                  className="artist_some"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={insta} />
                </a>
              )}
            </div>
            {artist?.text?.map((t, idx) => textToInsta(t, idx, !!artist.quote))}
          </div>
        </div>
      ))}
    </div>
  );
}
