import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";

import { AppContextProvider, AppContext } from "./context/AppContext.js";
import Pages from "./components/Pages.js";
import Popup1 from "./components/Popup1.js";
import "./main1.scss";

import {popup_content} from './components/popup_content.js'


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Content() {
  const location = useLocation();
  const {isOpen, openPopup} = useContext(AppContext);

  let query = useQuery();

  useEffect(() => {
    const id = query.get('id')
    if(!!id && !!popup_content[id]) {
      openPopup({ id, clickCoords: { x: 0, y: 0 } });
    }
  }, [query])

  return (
    <>
      <div className="app-container">
        <Switch location={location}>
          <Route path={"/festivaali"}>
            <Pages />
          </Route>
          <Route path={"/"}>
            <Redirect to="/festivaali" />
          </Route>
          <Route path="*">
            <div>Not Found</div>
          </Route>
        </Switch>
      </div>
      <Popup1
        isOpen={isOpen}
      />
      </>
  );
}

export default function Main1() {
  return (
    <AppContextProvider>
    <Router>
      <Content />
    </Router>
    </AppContextProvider>
  );
}
