import React, { useState } from "react";

import kolmio from "./kolmio1920.gif";
import "./info.scss";
import kjbackground from './KJF21-FBcover.png'
import bussi from './bussi.jpeg'

const ukk = [
  {
    q: "Mitä tapahtuma maksaa?",
    a:
      "Yleisesti ottaen tapahtuma on ilmainen. Lauantain Paluu Rikospaikalle iltajuhlaan tulee erillinen lipunmyynti (15€) ja torstain ulkoilmakino on myös maksullinen.",
  },
  {
    q: "Miten paikalle pääsee?",
    address: "Tuulimyllyntie 24, 04400 Järvenpää",
    image: bussi,
    a:
      "Pyörä on aina hyvä väline liikkua eikä kävellenkään matka ole hullumpi Järvenpään keskustasta. Rantareitti on tunnelmallinen! Autoilijoille parkkipaikkoja löytyy Tuulimyllyntien 4:n paikkeilta löytyvältä hiekkakentältä, sekä voi löytyä vanhan terveyskeskuksen kohdalta (huom. To & Pe kentällä Sirkus Finlandia). Tiedotamme myös taksitoimijoita varautumaan isommalla kapasiteetilla - ja voihan tilauslautallakin liikkua esim. Rantapuistosta Vanhankylänniemeen!",
      a2: ['Ensimmäinen dösä starttaa perjantaina juna-asemalta kohti Vanhista klo 17.40 ja takaisin Vanhiksesta asemalle klo 17.50. Tämän jälkeen lähdöt 20 minuutin välein, paitsi klo 20-21, jolloin bussikuskilla on tauko.', 'Bussikyyti maksaa 2 euroa suuntaansa. Kyydin voi maksaa Mobilepaylla numeroon 7448PJ. Myös käteinen käy, mutta varaathan tasarahan.', 'Bussin tunnistaa infotaulun tekstistä "Kulttuurijärvi".']
  },
  {
    q: "Onko ruoka ja juomatarjontaa?",
    a:
      "Kyllä! Anniskelua kahdessa pisteessä, Kartanon sisä- ja ulkotiloissa sekä Teatteripubilla eli kellarin terassilla (Ulkoilmakino kuuluu anniskelualueeseen). Lisäksi paikalla ruokaa tarjoilee ainakin Iron Pig Food:in ruokarekka ja Cittaristakin tuttu SLO Bros. Pikkusuolaista ja makeaakin lienee tarjolla.",
  },
  {
    q: "Miten varautua sään puolesta?",
    a:
      "Syyssää on aina mysteeri. Koska tapahtuma on pääosin ulkona, kannattaa varautua lämpimällä (kenties sateenkestävällä) vaatetuksella - mielummin liikaa kuin liian vähän. Alueella on myös narikat - omalla vastuulla.",
  },
  {
    q: "Kuka tapahtuman järjestää?",
    a:
      "Tapahtuman koordinoi Kulttuurijärvi Ry -niminen, voittoa tavoittelematon yhdistys. Ydinporukkamme koostuu kymmenestä järvenpääläislähtöisestä tyypistä, joiden toiveena on saada enemmän siistiä meininkiä Järvenpäähän. Muita jäseniä ja talkoolaisia on mukana myös runsaasti! Tapahtuma järjestetään yhteistyössä kaupungin, pääosin paikallisten yritysten, yhdistysten ja muiden toimijoiden kanssa.",
  },
  {
    q: "Kenelle tapahtuma on suunnattu?",
    a:
      "Kaikki kulttuurinnälkäiset, leppoisaa meininkiä arvostavat ovat tervetulleita! Etenkin iltaohjelmisto sopii parhaiten aikuisille ja jotkut (anniskelu)alueista ovatkin K-18.",
  },
  {
    q: "Miten korona vaikuttaa juhliin?",
    a:
      "Festarin sisällöstä iso osa tapahtuu ulkotiloissa ja sisätilojen väkimäärää rajoitetaan tarpeen ja säännösten mukaisesti. Tiedotamme ja ohjeistamme kulttuurikansaa AVI:n tuoreimpien ohjeiden mukaisesti.",
  },
];

const Info = ({ heading }) => {
  const [openIdx, setOpenIdx] = useState(0);

  return (
    <div className="info__container">
      <h1>{heading}</h1>
      <img className="info__banner" src={kjbackground} />
      <p>
        <i>
          Paikallista musameininkiä, päräyttävää valotaidetta,
          ulkoilmaleffanäytöksiä, designia ja maukasta sapuska- ja
          juomatarjontaa - eli letkeää kaupunkikulttuuria!
        </i>
      </p>
      <p>
        Kulttuurijärvi Festivaali järjestetään{" "}
        <b>Järvenpään Vanhankylänniemessä</b>, Vanhiksen Kartanon upeissa sisä-
        ja ulkopuitteissa <b>23.-25.9.2021</b>.
      </p>
      <p>
        Juhlameininkiin pääsee osallistumaan pääosin <b>ilmaiseksi</b>{" "}
        lukuunottamatta muutamaa sivu-tapahtumaa. Käy tutustumassa tarkemmin
        alueen karttaan, pian päivittyvään ohjelmistoon ja Kulttuurijärven
        meininkiin näiltä saiteilta ja some-kanavistamme - ja lähde mukaan
        luomaan kaupunkikulttuuria 😍
      </p>
      <p>
        Paikallisuuteen, urbaanihkoon kulttuuriin ja leppoisaan menoon arvonsa
        pohjaavat festarit sidotaan osaksi{" "}
        <b>
          <span>Järvenpään kaupungin 70v-juhlavuotta</span>
        </b>
        . Järvenpään kaupunki toimiikin tapahtuman pääyhteistyökumppanina ja
        tukijana tultuamme toiselle sijalle kaupungin järjestämässä osallistuvan
        budjetoinnin kilpailussa.
      </p>
      <div className="signature">
        <div className="kolmio__container">
          <img src={kolmio} className="kolmio__info" alt="logo" />
        </div>
        <div>Kulttuurijärvi Ry</div>
      </div>

      <div className="faq">
        <h2>Usein kysytyt kysymykset</h2>
        {ukk.map(({ q, a, address, image, a2 }, idx) => (
          <div key={idx} onClick={() => setOpenIdx(idx)}>
            <h4 className="faq__question">{q}</h4>
            <div style={{display: openIdx === idx ? 'inherit' : 'none'}}>
              {address && (
                <a href="https://goo.gl/maps/P1j5WSJvF7MVXLKD6" target="_blank" rel="noreferrer">
                  {address}
                </a>
              )}
              <p>{a}</p>
              {!!image && <img src={image} className="faq__answer__image"/>}
              {!!a2 && a2.map((t, idx) => <p key={idx}>{t}</p>)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Info;
