import React, { useEffect, useState, useMemo, createContext } from "react";

const INITIAL_STATE = {
  closePopup: () => null,
  id: '',
  isOpen: false,
  clickCoords: {x:0, y: 0},
  openPopup: () => null
};

export const AppContext = createContext(INITIAL_STATE);

export const AppContextProvider = (props) => {
  const [isOpen, setIsOpen] = useState(INITIAL_STATE.isOpen);
  const [id, setId] = useState(INITIAL_STATE.id)
  const [clickCoords, setClickCoords] = useState(INITIAL_STATE.clickCoords)

  const closePopup = () => {
    setIsOpen(false)
    setTimeout(() => setId(null), 300);
  }

  const openPopup = ({id, clickCoords}) => {
    setClickCoords(clickCoords);
    setIsOpen(true);
    setId(id);
  }

  return (
    <AppContext.Provider
      value={{
        closePopup,
        openPopup,
        clickCoords,
        id,
        isOpen
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
