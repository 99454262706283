import React, { useState } from "react";

import logo_fb from "./icons/fb-kelt.png";
import logo_insta from "./icons/insta-kelt.png";
import logo_email from "./icons/sposti-kelt.png";

import "./sponsors.scss";

const Sponsors = ({ heading }) => {
  return (
    <div className="sponsors__container">
      <div>
        <h1>{heading}</h1>
        <ul className="sponsors-list">
          <li>
            <h2>Järvenpään kaupunki</h2>
          </li>
          <li>
            <h2>Spurtti</h2>
          </li>
          <li>
            <h2>Sun Effects Oy</h2>
          </li>
          <li>
            <h2>Studio 123</h2>
          </li>
          <li>
            <h2>Foxshed</h2>
          </li>
          <li>
            <h2>Talerock Brewing</h2>
          </li>
          <li>
            <h2>JäPS Oy</h2>
          </li>
          <li>
            <h2 style={{ marginBottom: 0 }}>Aleksi Lantz</h2>
            <p style={{ margin: '0' }}>Aluevalojen suunnittelu ja toteutus</p>
          </li>
        </ul>

        <div className="sponsors-contact">
          <h3 className="">Ota yhteyttä:</h3>
          <a
            href="https://www.facebook.com/events/3479153468855363"
            target="_blank"
          >
            <img src={logo_fb} />
          </a>
          <a href="https://www.instagram.com/kulttuurijarvi/" target="_blank" rel="noreferrer">
            <img src={logo_insta} />
          </a>
          <a href="mailto:kulttuurijarvi@gmail.com" target="_blank" rel="noreferrer">
            <img src={logo_email} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Sponsors;
