import React, { useEffect, useContext } from "react";
import { useSpring, useTransition, animated, useSpringRef } from "react-spring";
import * as d3 from 'd3-ease'
import {popup_content, map_content} from './popup_content.js'
//import { useHistory } from "react-router-dom";
import useWindowDimensions from "./useWindowDimensions";
import PopupContent from './PopupContent.js'
import { AppContext } from "../context/AppContext.js";

import "./popup1.scss";
import kjbackground from './KJF21-FBcover.png'

export default function Popup1({ isOpen }) {
  const { width, height } = useWindowDimensions();
  const {id, clickCoords, closePopup} = useContext(AppContext);
  //const history = useHistory();

  const contents = [map_content, popup_content]
  const contentIdx = contents.findIndex(i => !!i[id])
  const content = contentIdx > -1 ? contents[contentIdx][id] : {}
  const transformOrigin =
    !!clickCoords && `${clickCoords.x}px ${clickCoords.y}px`;

  const transRef = useSpringRef();
  const props = useSpring({
    from: { opacity: 0 },
    to: { opacity: isOpen ? 1 : 0 },
  });
  const transitions = useTransition(isOpen, {
    ref: transRef,
    from: { transform: `scale(1.3) `, opacity: 0, borderRadius: '0%' },
    enter: { transform: `scale(1)`, opacity: 1, borderRadius: '0%' },
    leave: { transform: `scale(1.3)`, opacity: 0, borderRadius: '0%' },
    unique: true,
    config: {
      easing: d3.easePolyIn(1),
      tension: 150,
      precision: 0.01
      //velocity: 20 * 0.001
    }
  });

  useEffect(() => {
    transRef.start();
  }, [isOpen]);

  // useEffect(() => {
  //   console.log("BOMBOMB")
  //   if(contentIdx >= 0) {
  //     history.push({search: `id=${id}`})
  //   }
  // }, [id, contentIdx, history])

  if(contentIdx < 0) return null

  return (
    <>
    <div className={"popup__wrapper"} style={{overflowX: 'hidden', height: `${height}px`}}>
      <animated.div className={""} style={props} />
      {transitions((styles, item) => {
        return item ? (
          <animated.div className={"popup__content"} style={{...styles, transformOrigin}} onClick={closePopup}>
            <div className="popup__bg_image" style={{
              backgroundImage: `url(${kjbackground})`,
            }} />
            <div className="popup__overlay" />
            <PopupContent content={content} />
          </animated.div>
        ) : null;
      })}
    </div>
    </>
  );
}
