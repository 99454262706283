import React, { useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import imageMapResize from "image-map-resizer";

import { AppContext } from "../context/AppContext";
import "./map.scss";
// import kartta from "./kartta1.jpeg";
//import kartta2 from './kartta-glow-1600.gif'
import kartta2 from './kartta-glow-1600-uusi1.gif'
import {areas} from './map_areas'

import {hasIdContent} from './popup_content.js'


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Map = () => {
  const history = useHistory();
  const { openPopup } = useContext(AppContext);

  useEffect(() => {
    imageMapResize()
  }, []);

  const hover = (e) => {
  };

  const clickMap = (target) => (e) => {
    e.preventDefault();

    if (target.target === "ukk") {
      history.push("/festivaali/info");
      return;
    }

    //console.log("OMBOBMO", target, e, hasIdContent(target.target))
    hasIdContent(target.target) && openPopup({id: target.target, clickCoords: {x: e.clientX, y: e.clientY}});
  };

  //

  return (
    <div className="map-container">
      <div className="fill map-background">
        <img src={kartta2} useMap="#image-map" className="map-image" />
        <map name="image-map">
          {areas.map((a) => (
            <area
              key={a.title}
              target=""
              title={a.title}
              alt={a.title}
              coords={a.coords}
              shape={a.shape}
              onMouseEnter={hover}
              onClick={clickMap(a)}
            />
          ))}
        </map>
        <h2 className="map-instruction">Klikkaa kartan alueita!</h2>
      </div>
      </div>
  );
};

export default Map;
