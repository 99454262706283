import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import { useTransition, animated, useSpringRef } from "react-spring";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
//import { useGesture, useDrag } from "@use-gesture/react";

import Info from "./Info.js";
import Schedule from "./Schedule";
import Map from "./Map.js";
import Spring1 from "./Spring1";
import Sponsors from "./Sponsors.js";

import "./pages.scss";
import kjfgif from "./KJF1920.gif";
import kjflogogif from "./KJF414.gif";
import icon_schedule from "./icons/aikataulu-bold.png";
import icon_info from "./icons/info-bold.png";
import icon_sponsor from "./icons/yhteistyo-bold.png";
import icon_contact from "./icons/yhteys-bold.png";
import icon_map from "./icons/kartta1-bold.png";
import logo_kjt from "./Kulttuurijarvi-logo.png";
import logo_fb from "./icons/fb-kelt.png";
import logo_insta from "./icons/insta-kelt.png";
import logo_email from "./icons/sposti-kelt.png";

export const mainPath = "/festivaali";

const PAGES = {
  "": {
    id: "",
    title: {},
  },
  info: {
    id: "info",
    title: {},
  },
  aikataulu: {
    id: "aikataulu",
    title: {},
  },
  sponsorit: {
    id: "sponsorit",
    title: {},
  },
  kartta: {
    id: "kartta",
    title: {},
  },
};

const KEYS = Object.keys(PAGES);
const PATHS = Object.keys(PAGES).map((p) => `${mainPath}${!!p ? `/${p}` : ""}`);

const getPath = (key) => PATHS[KEYS.indexOf(key)];

const pathToKey = (key) => {
  return KEYS[PATHS.indexOf(key)];
};

const pages = {
  "": ({ style, history }) => (
    <animated.div style={{ ...style }}>
      <div className="pages__landing__container">
        <div className="pages__landing__img__container">
          <img src={kjfgif} alt="logo" className="pages__landing" />
        </div>
        <div className="pages__landing__contact-icons">
          <a
            href="https://www.facebook.com/events/3479153468855363"
            target="_blank"
            rel="noreferrer"
          >
            <img src={logo_fb} />
          </a>
          <a href="https://www.instagram.com/kulttuurijarvi/" target="_blank" rel="noreferrer">
            <img src={logo_insta} />
          </a>
          <a href="mailto:kulttuurijarvi@gmail.com" target="_blank" rel="noreferrer">
            <img src={logo_email} />
          </a>
        </div>
      </div>
    </animated.div>
  ),
  info: ({ style, history }) => (
    <animated.div style={{ ...style }}>
      <Info heading="Info" />
    </animated.div>
  ),
  aikataulu: ({ style, history }) => (
    <animated.div style={{ ...style }}>
      <Spring1 />
    </animated.div>
  ),
  sponsorit: ({ style, history }) => (
    <animated.div style={{ ...style }}>
      <Sponsors heading="Yhteistyössä" />
    </animated.div>
  ),
  kartta: ({ style, history }) => (
    <animated.div style={{ ...style }}>
      <Map />
    </animated.div>
  ),
};

// const V_THRESHOLD = 0.3;
//
// const goLeft = (key) => {
//   const idxLeft = KEYS.indexOf(key) - 1;
//   const nextIdx = idxLeft >= 0 ? idxLeft : KEYS.length - 1;
//   return PATHS[nextIdx];
// };
//
// const goRight = (key) => {
//   const idxRight = KEYS.indexOf(key) + 1;
//   const nextIdx = idxRight >= KEYS.length ? 0 : idxRight;
//   return PATHS[nextIdx];
// };

function Content() {
  const location = useLocation();
  const history = useHistory();
  const [index, set] = useState("");
  const [animateRight, setAnimateRight] = useState(false);
  const scrollDestinationRef = useRef();

  // const bind = useDrag(
  //   (state) => {
  //     const [vx, vy] = state.velocity;
  //     const { last, swipe } = state;
  //     const [swipeX] = swipe;
  //
  //
  //     if (swipeX === -1) {
  //       const next = goRight(index);
  //       setAnimateRight(true);
  //       history.push(next);
  //     } else if (swipeX === 1) {
  //       const next = goLeft(index);
  //       setAnimateRight(false);
  //       history.push(next);
  //     }
  //   },
  //   {
  //     swipe: {
  //       distance: [5, 5],
  //       velocity: [0.05, 0.05],
  //     },
  //     //filterTaps: true
  //   }
  // );

  // const onClick = useCallback(() => set(state => (state + 1) % 3), [])
  useEffect(() => {
    const pathname = location.pathname.toLowerCase()
    const idx = PATHS.indexOf(pathname);
    if (idx >= 0) {
      const key = KEYS[idx];
      const page = PAGES[key];
      set(key);
    } else {
      const key = pathToKey(pathname);
      set(key);
    }
    // if (location.pathname === `${mainPath}`) set("");
    // if (location.pathname === `${mainPath}/b`) set("b");
    // if (location.pathname === `${mainPath}/c`) set("c");
  }, [location.pathname]);

  const transRef = useSpringRef();
  const transitions = useTransition(index, {
    ref: transRef,
    keys: null,
    from: {
      opacity: 0,
      transform: `translate3d(${animateRight ? "" : "-"}100%,0,0)`,
    },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    leave: {
      opacity: 0,
      transform: `translate3d(${animateRight ? "-" : ""}100%,0,0)`,
    },
  });
  useEffect(() => {
    transRef.start();
  }, [index]);

  const goTo = (index) => {
    const path = PATHS[index];
    const curr = PATHS.indexOf(location.pathname);
    const next = PATHS.indexOf(path);
    const directionToRight = curr < next;
    setAnimateRight(directionToRight);
    history.push(path);
  };

  //{...bind()}

  return (
    <div className="pages__container">
      <div className={`pages__fill`}>
        {transitions((style, i) => {
          const Page = pages[i];
          return <Page style={style} history={history} />;
        })}
      </div>
      <div
        className="pages__top__navbar__container"
        style={{ opacity: KEYS.indexOf(index) === 0 ? 0 : 1 }}
      >
        <img src={kjflogogif} onClick={() => goTo(0)} />
      </div>
      <div className="pages__navbar__container">
        <div className="pages__navbar">
          <div
            className={KEYS.indexOf(index) === 1 ? "active" : ""}
            onClick={() => goTo(1)}
          >
            <img src={icon_info} />
            <p>Info</p>
          </div>
          <div
            className={KEYS.indexOf(index) === 2 ? "active" : ""}
            onClick={() => goTo(2)}
          >
            <img src={icon_schedule} />
            <p>Aikataulu</p>
          </div>
          <div
            className={KEYS.indexOf(index) === 3 ? "active" : ""}
            onClick={() => goTo(3)}
          >
            <img src={icon_sponsor} />
            <p>Yhteistyö</p>
          </div>
          <div
            className={KEYS.indexOf(index) === 4 ? "active" : ""}
            onClick={() => goTo(4)}
          >
            <img src={icon_map} />
            <p>Kartta</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Pages() {
  return (
    <Route path="*">
      <Content />
    </Route>
  );
}
