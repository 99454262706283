export const areas = [
  { title: "Love", target: "love", coords: "487,1824,706,2097", shape: "rect" },
  {
    title: "Sibbe",
    target: "sibbe",
    coords: "1093,1898,1277,2131",
    shape: "rect",
  },
  {
    title: "Sauna",
    target: "sauna",
    coords: "1213,1516,1526,1844",
    shape: "rect",
  },
  {
    title: "Kartano",
    target: "kartano",
    coords: "714,1189,959,1617",
    shape: "rect",
  },
  {
    title: "Pää-Alue",
    target: "paaalue",
    coords: "405,1196,702,1628",
    shape: "rect",
  },
  { title: "UKK", target: "ukk", coords: "1236,1145,1423,1367", shape: "rect" },
  {
    title: "Ruoka-alue",
    target: "ruoka",
    coords: "389,745,820,971",
    shape: "rect",
  },
  { title: "Kino", target: "kino", coords: "854,338,1178,878", shape: "rect" },
  { title: "Mylly", target: "mylly", coords: "242,441,471,670", shape: "rect" },
  { title: "Vene", target: "vene", coords: "519,482,700,697", shape: "rect" },
  {
    title: "Mörkö",
    target: "morko",
    coords: "542,2392,1020,2547",
    shape: "rect",
  },
  {
    title: "Pytinki",
    target: "pytinki",
    coords: "1023,1066,1206,1320",
    shape: "rect",
  },
  {
    title: "Beer",
    target: "beer",
    coords: "1016,1375,1222,1477",
    shape: "rect",
  },
  { title: "Puu", target: "puu", coords: "1185,339,1384,605", shape: "rect" },
  { title: "Tuli", target: "tuli", coords: "847,1799,997,2008", shape: "rect" },
  {
    title: "Banneri",
    target: "banneri",
    coords: "1061,2267,1513,2452",
    shape: "rect",
  },
];
