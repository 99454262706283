import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import { useSpring, config } from "react-spring";
import { debounce } from "lodash";
import useOnScreen from "./useOnScreen";
import useWindowDimensions from "./useWindowDimensions";

import { AppContext } from "../context/AppContext";

import popupicon from "./icons/popupicon.png";
import kolmio from "./kolmio1920.gif";
import sibbe from "./Sibbelonkero.png";
import mylly from "./Mylly.png";

const KJ_SCHEDULE = {
  thursday: {
    name: "Torstai 23.9.",
    icon: mylly,
    content: [
      {
        id: "Yleinen",
        schedule: [
          {
            time: "19.00",
            desc: "RUOKAKOJUT, TAIDEHUONEET, VALOTAIDE",
          },
          { time: "24.00", desc: "ALUE KIINNI" },
        ],
      },
      {
        id: "Teatteripubi (Ulkoilmakino)",
        schedule: [
          { time: "19.00", desc: "AUKEAA" },
          {
            time: "20.30",
            desc: "HYTTI NRO 6",
            link: "hytti",
            buy_link:
              "https://holvi.com/shop/studio123jpaa/product/1cc6896653d22e097fbb153db9460a58/",
          },
        ],
      },
      {
        id: "Pääalue",
        schedule: [{ time: "19.00", desc: "AVAJAISET" }],
      },
    ],
  },
  friday: {
    name: "Perjantai 24.9.",
    icon: sibbe,
    content: [
      {
        id: "Yleinen",
        schedule: [
          {
            time: "17.00",
            desc:
              "RUOKAKOJUT, TAIDEHUONEET, VALOTAIDE",
          },
          { time: "01.00", desc: "ALUE KIINNI" },
        ],
      },
      {
        id: "Teatteripubi (Ulkoilmakino)",
        schedule: [
          { time: "17.00", desc: "AUKEAA" },
          { time: "20.00", desc: "LYHYTDOKUMENTTEJA", link: 'dokkarit' },
        ],
      },
      {
        id: "Pääalue",
        desc: "Huom! Aikataulut voivat elää",
        schedule: [
          { time: "18.00", desc: "GEO", link: "geo" },
          { time: "19.00", desc: "ASTRAL BAZAAR", link: "bazaar" },
          { time: "20.00", desc: "BULLS ON PARADE", link: "bullsonp" },
          { time: "21:00", desc: "ENTÄS PELTONEN?", link: "peltonen" },
          { time: "22:00", desc: "PAHA VAANII", link: "pahis" },
        ],
      },
    ],
  },
  saturday: {
    name: "Lauantai 25.9.",
    icon: kolmio,
    content: [
      {
        id: "Yleinen",
        schedule: [
          { time: "14.00", desc: 'TAIDEHUONEET AUKI'},
          { time: "17.00", desc: 'TAIDEHUONEET KIINNI'},
          {
            time: "17.00",
            desc: "RUOKAKOJUT, VALOTAIDE",
          },
          { time: "24:00", desc: "ALUE KIINNI" },
        ],
      },
      {
        id: "Teatteripubi",
        schedule: [
          { time: "17.00", desc: "AUKEAA" },
          { time: "22.00", desc: "KIINNI - VOI MUUTTUA" },
        ],
      },
      {
        id: "Pääalue",
        schedule: [
          {
            time: "18.00",
            desc: "RIKOSPAIKKA",
            link: "rikospaikka",
            buy_link:
              "https://billetto.fi/e/kulttuurijarvi-festivaali-rikospaikka-liput-565197",
            soldout: true,
          },
        ],
      },
    ],
  },
};

const bottom_bar_days = ["To 23.9.", "Pe 24.9.", "La 25.9."];

const getParallaxLayerOffsets = (refArr) =>
  refArr.map((ref) => Math.abs(ref.current.getBoundingClientRect().y));
const getParallaxLayerOffsetsB = (refArr) =>
  refArr.map((ref) => ref.current.getBoundingClientRect().y);

let isScrolling = null;

const Spring = () => {
  const { content, openPopup, clickCoords } = useContext(AppContext);
  const { height, width } = useWindowDimensions();
  const isMobile = width <= 600;

  const [selectedDay, setSelectedDay] = useState(0);
  const scrollRef = useRef();
  const parallaxRef = useRef();

  const refThu = useRef();
  const refFri = useRef();
  const refSat = useRef();

  const refs = [refThu, refFri, refSat];

  const handleClick = (e, id) => {
    e.stopPropagation();
    if (!id) return;
    openPopup({ id, clickCoords: { x: e.clientX, y: e.clientY } });
  };

  // const isThursday = useOnScreen(refThu);
  // const isFriday = useOnScreen(refFri);
  // const isSaturday = useOnScreen(refSat);
  // console.log("KASOKDASOKDASO", isThursday, isFriday, isSaturday);
  //
  // useEffect(() => {
  //   if (isThursday) verify(0);
  //   if (isFriday) verify(1);
  //   if (isSaturday) verify(2);
  // }, [isThursday, isFriday, isSaturday]);
  //
  // const verify = useCallback(
  //   debounce((idx) => {
  //     console.log(`processing ${idx}`);
  //     setSelectedDay(idx);
  //   }, 1000),
  //   []
  // );

  const selectDay = (idx) => {
    if (selectedDay === idx) return;
    scrollRef.current.scrollTo(idx);
    setSelectedDay(idx);
  };

  const handleScroll = useCallback(() => {
    window.clearTimeout(isScrolling);
    isScrolling = setTimeout(() => {
      const refs = [refThu, refFri, refSat];
      const offsets = getParallaxLayerOffsets(refs);
      const idx = offsets.indexOf(Math.min(...offsets));
      if (selectedDay !== idx) setSelectedDay(idx);
    }, 1200);
  }, [selectedDay]);

  useEffect(() => {
    if (!parallaxRef.current) return;
    parallaxRef.current.children[0].addEventListener("scroll", handleScroll);
    return () => window.clearTimeout(isScrolling);
  }, [handleScroll, scrollRef]);

  const parallaxLayerStyles = {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
    ...(!isMobile ? { alignItems: "center" } : undefined),
  };

  return (
    <>
      <div className="schedule__buttons">
        {bottom_bar_days.map((d, idx) => (
          <p
            key={idx}
            className={selectedDay === idx ? "active" : ""}
            onClick={() => selectDay(idx)}
          >
            {d}
          </p>
        ))}
      </div>
      <div ref={parallaxRef}>
        <Parallax pages={3} style={{ top: "0", left: "0" }} ref={scrollRef}>
          {Object.entries(Object.entries(KJ_SCHEDULE)).map(
            ([index, [key, value]]) => {
              return (
                <div key={key}>
                  <ParallaxLayer offset={index} speed={1.5}>
                    <img src={value.icon} className="kolmio__r" alt="logo" />
                  </ParallaxLayer>
                  <ParallaxLayer
                    offset={index}
                    speed={1}
                    style={parallaxLayerStyles}
                  >
                    <div className="schedule__container" ref={refs[index]}>
                      <h1>{value.name}</h1>
                      {value.content.map((stage) => (
                        <div className="schedule__container__content" key={stage.id}>
                          <h2>{stage.id}</h2>
                          <span className="schedule__stage_desc">
                            {stage.desc}
                          </span>
                          {stage.schedule.map(
                            ({ time, desc, link, buy_link, soldout }, i) => (
                              <p
                                key={`${time}_${i}`}
                                onClick={(e) => handleClick(e, link)}
                                className={`schedule__row ${
                                  !!link ? "schedule__link" : ""
                                }`}
                              >
                                <b>{time}</b> {desc} <img src={popupicon} />
                                {!soldout && !!buy_link && (
                                  <>
                                    <br />
                                    <a
                                      href={buy_link}
                                      className="buy_link"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      OSTA LIPUT TÄSTÄ
                                    </a>
                                  </>
                                )}
                                {soldout && (
                                  <>
                                    <br />{" "}
                                    <span className="soldoutt">
                                      LOPPUUNMYYTY
                                    </span>
                                  </>
                                )}
                              </p>
                            )
                          )}
                        </div>
                      ))}
                    </div>
                  </ParallaxLayer>
                </div>
              );
            }
          )}
        </Parallax>
      </div>
    </>
  );
};

export default Spring;
